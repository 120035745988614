import Axios from 'axios';

export const sendDataToMake = async ({ userName, email, table, details }) => {
  const analyticsData = {
    Name: userName,
    Email: email,
    input: details,
    output: table,
    userGaId: window?.ga?.getAll?.()?.[0]?.get('clientId')
  };

  await Axios(
    'https://6ixkdacfdy2avrv7gwndco7oe40ycstr.lambda-url.us-east-1.on.aws/',
    {
      method: 'POST',
      data: transformRequestData(analyticsData)
    }
  );
};

function transformRequestData(inputData) {
  const basicInfo = {
    name: inputData.Name,
    email: inputData.Email,
    idea:
      inputData.input.AIQuestions.find(
        q => q.question === 'What is the idea of your project?'
      )?.answer || '',
    domain:
      inputData.input.AIQuestions.find(
        q => q.question === 'Please choose business domain'
      )?.answer || '',
    budget:
      inputData.output.find(item => item['Feature Name'] === 'Total')?.[
        'Total Budget'
      ] || '',
    utm: inputData.userGaId
  };

  const excelData = inputData.output
    .filter(item => item['Feature Name'] !== 'Total')
    .map(item => ({
      'Feature Name': item['Feature Name'],
      'User Story': item['User Story'],
      'Acceptance Criteria': item['Acceptance Criteria'],
      '3rd Party Services': item['3rd Party Services'],
      Backend: item['Backend'],
      Frontend: item['Frontend'],
      'UI/UX': item['UI/UX'],
      QA: item['QA'],
      'BA/PM': item['BA/PM']
    }));

  const totals = inputData.output.find(
    item => item['Feature Name'] === 'Total'
  );
  if (totals) {
    excelData.push({
      'Feature Name': 'Total',
      'User Story': '',
      'Acceptance Criteria': '',
      '3rd Party Services': '',
      Backend: totals['Backend'],
      Frontend: totals['Frontend'],
      'UI/UX': totals['UI/UX'],
      QA: totals['QA'],
      'BA/PM': totals['BA/PM']
    });
  }

  const convertToCSV = data => {
    const headers = Object.keys(data[0]);
    const csvRows = [
      headers.join('\t'),
      ...data.map(row =>
        headers
          .map(
            header => `"${(row[header] || '').toString().replace(/"/g, '""')}"`
          )
          .join('\t')
      )
    ];
    return csvRows.join('\n');
  };

  return {
    basicInfo,
    excelData,
    csvString: convertToCSV(excelData)
  };
}
